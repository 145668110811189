import { Component, ElementRef, Input } from '@angular/core';
import { Button, ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { defer, Observable } from 'rxjs';

@Component({
    selector: 'rc-ui-button',
    styleUrl: './button.component.scss',
    template: `
    <div
      [pTooltip]="disabled ? disabledReason : undefined"
      tooltipPosition="bottom"
      class="pointer-auto"
    >
      <p-button
        [badge]="badge"
        [disabled]="disabled"
        [icon]="icon"
        [iconPos]="iconPos"
        [label]="label"
        [loadingIcon]="loadingIcon"
        [loading]="loading"
        [outlined]="outlined"
        [raised]="raised"
        [rounded]="rounded"
        [severity]="severity"
        [size]="size"
        [type]="type"
        [rounded]="rounded"
        [text]="text"
        (onClick)="handleClick($event)"
        [styleClass]="styleClass"
      ></p-button>
    </div>
  `,
    imports: [ButtonModule, TooltipModule]
})
export class RcUiButtonComponent<T = any> extends Button {
  /**
   * Provide an observable to subscribe to when button is clicked,
   * automatically handling loading when observable starts and completes
   */
  @Input({ required: true }) public rcClick!: Observable<T>;
  @Input() public disabledReason?: string;

  constructor(el: ElementRef) {
    super(el);
  }

  public handleClick($event: MouseEvent): void {
    defer(() => {
      this.loading = true;
      return this.rcClick;
    }).subscribe({
      complete: () => {
        this.loading = false;
      },
    });
  }
}
